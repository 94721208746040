@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

:root {

  --primary-color: #0B5D18;     
  --secondary-color: #3DAC54;  
  --background-color: #F8FAF5; 
  --text-color: #084012;       
  --card-background: #D4E9C5;  
  --header-background: #0B5D18; 
  --border-color: #3DAC54;     
  --button-hover: #138A25;     
  --title-color: #0B5D18;    
  --light-text: #FFFFFF;      
  --subtle-background: #EAF5E0;
  --accent-color: #4AC85B;     
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--background-color);
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

header {
  background-color: var(--primary-color);
  padding: 15px 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

header h1 {
  font-size: 1.6rem;
  color: var(--light-text);
  margin: 0;
  margin-right: 20px;
}

nav ul {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  margin: 0;
}

nav ul li {
  margin-left: 20px;
}

nav ul li a {
  text-decoration: none;
  color: var(--light-text);
  font-weight: 500;
  transition: color 0.3s ease;
  font-size: 0.9rem;
}

nav ul li a:hover {
  color: var(--accent-color);
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

h2 {
  font-size: 2.2rem;
  color: var(--primary-color);
  margin-bottom: 25px;
  text-align: left;
  padding: 0;
}

.home {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  position: relative;
  overflow: hidden;
  padding: 20px 0;
}

.home p {
  font-size: 1rem;
  max-width: 600px;
  margin: 0 auto 25px;
  line-height: 1.6;
}

.home h2, .home p {
  position: relative;
  z-index: 1;
}

.animated-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}

.shape {
  position: absolute;
  opacity: 0.15;
}

.circle1 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: var(--secondary-color);
  left: 10%;
  top: 20%;
  animation: float 12s ease-in-out infinite;
}

.circle2 {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--primary-color);
  right: 20%;
  bottom: 15%;
  animation: float 10s ease-in-out infinite 1s;
}

.triangle1 {
  width: 0;
  height: 0;
  border-left: 60px solid transparent;
  border-right: 60px solid transparent;
  border-bottom: 120px solid var(--border-color);
  right: 15%;
  top: 30%;
  animation: float 14s ease-in-out infinite 0.5s;
}

.triangle2 {
  width: 0;
  height: 0;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-bottom: 80px solid var(--secondary-color);
  left: 25%;
  bottom: 25%;
  animation: float 16s ease-in-out infinite 1.5s;
}

.square1 {
  width: 80px;
  height: 80px;
  background-color: var(--secondary-color);
  left: 50%;
  top: 40%;
  animation: float 18s ease-in-out infinite 1s;
}

.square2 {
  width: 50px;
  height: 50px;
  background-color: var(--accent-color);
  right: 35%;
  bottom: 40%;
  animation: float 20s ease-in-out infinite 2s;
}

@keyframes float {
  0%, 100% { transform: translate(0, 0) rotate(0deg); }
  25% { transform: translate(15px, -20px) rotate(5deg); }
  50% { transform: translate(-10px, 25px) rotate(-8deg); }
  75% { transform: translate(-20px, -15px) rotate(10deg); }
}

.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  position: relative;
  z-index: 1;
  margin-top: 20px;
}

.cta-button {
  display: inline-block;
  background-color: var(--secondary-color);
  color: var(--light-text);
  padding: 12px 24px;
  text-decoration: none;
  border-radius: 5px;
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  font-size: 0.9rem;
  border: none;
}

.cta-button:hover {
  background-color: var(--button-hover);
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.project-grid, .skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 0;
}

.project-card, .skill-card {
  background-color: var(--card-background);
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid var(--border-color);
}

.project-card:hover, .skill-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.12);
}

.project-card h3, .skill-card h3 {
  font-size: 1.3rem;
  color: var(--primary-color);
  margin-bottom: 12px;
  font-weight: 600;
}

.project-card p, .skill-card p {
  font-size: 0.9rem;
  margin-bottom: 12px;
}

.skill-card ul {
  list-style-type: none;
  padding-left: 15px;
  margin-bottom: 0;
}

.skill-card ul li {
  margin-bottom: 10px;
  position: relative;
  font-size: 0.9rem;
}

.skill-card ul li::before {
  content: "•";
  color: var(--secondary-color);
  position: absolute;
  left: -15px;
}

.skill-card ul li:last-child {
  margin-bottom: 0;
}

.read-more {
  display: inline-block;
  background-color: var(--secondary-color);
  color: var(--light-text);
  padding: 6px 12px;
  text-decoration: none;
  border-radius: 20px;
  font-weight: 500;
  transition: all 0.3s ease;
  font-size: 0.9rem;
  margin-top: 10px;
}

.read-more:hover {
  background-color: var(--button-hover);
  transform: translateY(-3px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.project-detail {
  background-color: var(--card-background);
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  max-width: 800px;
  margin: 0 auto;
  border: 1px solid var(--border-color);
}

.project-detail h2 {
  color: var(--primary-color);
  font-size: 2.2rem;
  margin-bottom: 20px;
  text-align: left;
  padding: 0;
}

.project-detail h3 {
  color: var(--secondary-color);
  font-size: 1.5rem;
  margin-top: 35px;
  margin-bottom: 15px;
  border-bottom: 2px solid var(--secondary-color);
  padding-bottom: 5px;
}

.project-detail h4 {
  color: var(--primary-color);
  font-size: 1.2rem;
  margin-top: 25px;
  margin-bottom: 15px;
}

.project-detail p, 
.project-detail h3, 
.project-detail ul,
.project-detail .project-links {
  padding-left: 0;
  margin-left: 0;
}

.project-detail p {
  margin-bottom: 20px;
  line-height: 1.6;
  font-size: 0.9rem;
}

.project-detail strong {
  color: var(--primary-color);
}

.project-detail em {
  color: var(--secondary-color);
  font-style: italic;
}

.project-detail ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 15px;
}

.project-detail ul li {
  background-color: var(--secondary-color);
  color: var(--light-text);
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 0.8rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-detail ul li:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.project-type {
  font-size: 1.1rem;
  color: var(--text-color);
  margin-bottom: 20px;
  font-weight: 500;
}

.project-section {
  background-color: var(--subtle-background);
  border-radius: 8px;
  padding: 25px;
  margin: 20px 0 25px 0;
  border: 1px solid var(--border-color);
}

.role-section {
  margin-bottom: 35px;
}

.role-section:last-child {
  margin-bottom: 0;
}

.demo-account {
  background-color: var(--secondary-color);
  color: var(--light-text);
  padding: 8px 16px;
  border-radius: 5px;
  display: inline-block;
  margin: 5px 10px;
  font-size: 0.9rem;
}

.project-links {
  display: flex;
  gap: 15px;
  margin: 20px 0;
}

.project-link {
  display: inline-block;
  padding: 8px 16px;
  background-color: var(--secondary-color);
  color: var(--light-text);
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 0.9rem;
}

.project-link:hover {
  background-color: var(--button-hover);
  transform: translateY(-3px);
}

.contact {
  max-width: 600px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
}

.contact h2 {
  font-size: 2.2rem;
  color: var(--primary-color);
  margin-bottom: 25px;
  text-align: left;
  padding: 0;
  margin-left: -5px;
}

.contact form {
  width: 100%;
}

.contact form > div {
  margin-bottom: 20px;
}

.contact label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 0.9rem;
  color: var(--text-color);
}

.contact input,
.contact textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  font-size: 0.9rem;
  background-color: var(--subtle-background);
  color: var(--text-color);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  box-sizing: border-box;
}

.contact input:focus,
.contact textarea:focus {
  outline: none;
  border-color: var(--secondary-color);
  box-shadow: 0 0 0 2px rgba(61, 172, 84, 0.2);
}

.contact textarea {
  height: 150px;
  resize: vertical;
}

.contact button {
  background-color: var(--secondary-color);
  color: var(--light-text);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  width: auto;
  min-width: 150px;
}

.contact button:hover {
  background-color: var(--button-hover);
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

footer {
  text-align: center;
  padding: 15px 0;
  background-color: var(--primary-color);
  color: var(--light-text);
  border-top: 1px solid var(--border-color);
  font-size: 0.8rem;
}

.skills-showcase {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.skills-showcase span {
  color: var(--light-text);
  opacity: 0.9;
}

@media (max-width: 768px) {
  header {
    padding: 10px 15px;
    flex-direction: column;
    align-items: flex-start;
  }

  header h1 {
    font-size: 1.4rem;
    margin-bottom: 5px;
  }

  nav ul {
    justify-content: flex-start;
    width: 100%;
  }

  nav ul li {
    margin-left: 0;
    margin-right: 15px;
    margin-bottom: 0;
  }

  nav ul li a {
    font-size: 0.8rem;
  }

  main {
    padding: 10px;
    justify-content: flex-start;
  }

  h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }

  .home {
    padding: 10px 0;
  }

  .home h2 {
    font-size: 1.6rem;
  }

  .home p {
    font-size: 0.85rem;
    margin-bottom: 20px;
  }

  .cta-buttons {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .cta-button {
    width: 90%;
    text-align: center;
    padding: 12px 0;
    margin-bottom: 10px;
    font-size: 0.85rem;
  }

  .project-grid, .skills-grid {
    grid-template-columns: 1fr;
  }

  .project-card h3, .skill-card h3 {
    font-size: 1.2rem;
  }

  .project-card p, .skill-card p, .skill-card ul li {
    font-size: 0.85rem;
  }

  .project-detail {
    padding: 20px;
  }

  .project-detail h2 {
    font-size: 1.6rem;
  }

  .project-detail h3 {
    font-size: 1.3rem;
  }

  .project-detail h4 {
    font-size: 1.1rem;
  }

  .project-detail p {
    font-size: 0.85rem;
  }

  .project-section {
    padding: 15px;
  }

  .demo-account {
    display: block;
    margin: 10px 0;
    text-align: center;
  }

  footer {
    padding: 10px 0;
    font-size: 0.75rem;
  }
}